export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,2,5,7,9];

export const dictionary = {
		"/": [13],
		"/(app)/account": [~14,[2],[3]],
		"/(app)/account/payment-methods": [~15,[2],[3]],
		"/(app)/account/payment-methods/add": [16,[2],[3]],
		"/(app)/account/payment-methods/update": [~17,[2],[3]],
		"/(app)/account/payouts/update": [~18,[2],[3]],
		"/api/waitlist": [43],
		"/auth/auth-code-error": [44,[],[11]],
		"/blog": [~45,[12]],
		"/blog/[slug]": [~46,[12]],
		"/(app)/calls": [19,[2],[3]],
		"/(app)/calls/[callId]": [~20,[4]],
		"/(app)/chats": [21,[2,5],[3]],
		"/(app)/chats/[chatId]": [~22,[2,5],[3]],
		"/(app)/checkout": [~23,[2],[3]],
		"/(app)/dashboard": [~24,[2],[3]],
		"/(app)/delete-account": [25,[2],[3]],
		"/faq": [47],
		"/help": [48],
		"/(app)/home": [26,[2],[3]],
		"/join": [~49],
		"/(app)/listings": [~27,[2,6],[3]],
		"/(app)/listings/create": [28,[2,6],[3]],
		"/(app)/listings/edit/[listingId]": [~29,[2,6],[3]],
		"/(app)/orders": [~30,[2],[3]],
		"/(app)/posts": [~31,[2],[3]],
		"/(app)/posts/create": [~33,[2],[3]],
		"/(app)/posts/[slug]": [~32,[2],[3]],
		"/privacy": [~50],
		"/reset-password": [51],
		"/(app)/sales": [~34,[2],[3]],
		"/sexyjobs": [~52],
		"/signin": [53],
		"/terms": [54],
		"/(app)/tips": [~35,[2],[3]],
		"/(app)/update-password": [36,[7]],
		"/(app)/upload": [37,[2],[3]],
		"/(app)/verified": [38,[8]],
		"/(app)/verified/channels": [39,[8,9]],
		"/(app)/verified/channels/[channelId]": [~40,[8,9]],
		"/(app)/verified/unauthorized": [41,[8]],
		"/[providerName]": [~42,[],[10]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';